import KastAppClient, { Axios, KastAppClientForbiddenType, LOGIN_LEVEL, OAuth2DeviceDTO, OAuth2DeviceVerifyDTO, Tokens, UserOutputDTO } from '@kalyzee/kast-app-module';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  Middleware,
  PayloadAction
} from '@reduxjs/toolkit';
import { ApiError } from '../../helpers/apiError';
import { setToken as setLocalStorageToken, TokenType } from '../../helpers/storage';
import { toastApiError, toastError } from '../../helpers/toast';
import { RequestLoginGuest, RequestLoginLocal, RequestOauth2AllowDevice } from '../../interfaces/request';
import { AuthStatus } from '../../interfaces/session';
import { SocketStatus } from '../../interfaces/socket';
import { SessionMode, logout, setAuthStatus, setRefreshToken, setToken, login, setUser } from '../session/slices';
import { setSocketStatus } from '../socket/slices';
import requestActions from './actions';
import { AppMiddlewareApi } from '../store';
import { NavigateGlobalQuery, getGlobalQueryLabel } from '../../helpers/navigate';
import { apiClient } from '../../helpers/request';

/// Redux Action Handlers ///

interface ActionEntryWithPayload<P = any> {
  actionCreator: ActionCreatorWithPayload<P>,
  handle: (
    client: KastAppClient,
    store: AppMiddlewareApi,
    payload: P,
  ) => (Promise<any> | void),
}

interface ActionEntry {
  actionCreator: ActionCreatorWithoutPayload,
  handle: (client: KastAppClient, store: AppMiddlewareApi) => void,
}

const reduxActionHandlers: (ActionEntry | ActionEntryWithPayload)[] = [
  {
    actionCreator: requestActions.requestLoginLocal,
    handle: async (client: KastAppClient, store: AppMiddlewareApi, payload: RequestLoginLocal) => {
      let response: Tokens | undefined;
      try {
        response = await client.login(payload.email, payload.password);
        if (!response?.token) throw new Error('Token is undefined');
        store.dispatch(login({ token: response.token, refreshToken: response.refreshToken }))
      } catch(err: any) {
        if (ApiError.isApiError(err)) toastApiError(err);
        else toastError(`Error login : ${err?.message}`);
      }
      return response;
    },
  },
  {
    actionCreator: requestActions.requestLoginGuest,
    handle: async (client: KastAppClient, store: AppMiddlewareApi, payload: RequestLoginGuest) => {
      let response: Tokens | undefined;
      try {
        // response = (await client.client.post('/user/login/guest', { username: payload.name, firstname: "Dorian"}, { params: { include: ['user', 'identity']}}))?.data;
        response = await client.loginAsGuest({ username: payload.name }, { include: ["user", "identity"]});
        if (!response?.token) throw new Error('Token is undefined');
        client.setTokens(response);
        store.dispatch(login({ token: response.token, refreshToken: response.refreshToken }))
      } catch(err: any) {
        if (ApiError.isApiError(err)) toastApiError(err);
        else toastError(`Error login : ${err?.message}`);
      }
      return response;
    },
  },
  {
    actionCreator: requestActions.requestLogout,
    handle: (client: KastAppClient, store: AppMiddlewareApi) => {
      store.dispatch(logout());
      return undefined;
    },
  },
  {
    actionCreator: requestActions.requestGetMe,
    handle: async (client: KastAppClient, store: AppMiddlewareApi) => {
      let response: Axios.AxiosResponse<UserOutputDTO, any> | undefined;
      try {
        response = await client.user.getMe();
        store.dispatch(setUser(response.data));
      } catch(err: any) {
        if (ApiError.isApiError(err)) toastApiError(err);
        else toastError(`Error get me : ${err?.message}`);
      }
      return response;
    },
  },
  {
    actionCreator: requestActions.requestSocketToken,
    handle: async (client: KastAppClient, store: AppMiddlewareApi) => {
      return await client.socket.getToken();
    },
  },
  {
    actionCreator: requestActions.requestOauth2AllowDevice,
    handle: async (client: KastAppClient, store: AppMiddlewareApi, payload: RequestOauth2AllowDevice) => {
      const data: OAuth2DeviceVerifyDTO = {
        user_code: payload.code,
        allow: true,
      };
      return await client.oAuth2.deviceVerify(data);
    },
  },
];

const reduxActionMatcher = (
  client: KastAppClient,
  store: AppMiddlewareApi,
  action: PayloadAction,
): Promise<any> | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const handler of reduxActionHandlers) {
    if (handler.actionCreator.match(action)) {
      const result = handler.handle(client, store, action.payload);
      if (result instanceof Promise) return result;
      return undefined;
    }
  }
  return undefined;
};

/// Middleware ///

export const requestMiddleware: Middleware = (store: AppMiddlewareApi) => {
  // eslint-disable-next-line arrow-body-style
  apiClient.addEventListener('forbidden', (type: KastAppClientForbiddenType) => {
    const mode = store.getState().session.mode;
    store.dispatch(logout());
    store.dispatch(setSocketStatus(SocketStatus.Offline));
    let reason: string | undefined;
    if (type === KastAppClientForbiddenType.USER_IS_DISABLED) reason = 'udisabled';
    if (type === KastAppClientForbiddenType.USER_IS_BANNED) reason = 'ubanned';
    if (type === KastAppClientForbiddenType.USER_TRIAL_PERIOD_IS_OVER) reason = 'utrialperiodisover';
    if (type === KastAppClientForbiddenType.ORGANIZATION_IS_DISABLED) reason = 'odisabled';
    if (type === KastAppClientForbiddenType.ORGANIZATION_TRIAL_PERIOD_IS_OVER) reason = 'otrialperiodisover';
    if (reason) {
      window.location.href = `/forbidden?r=${reason}${mode === SessionMode.SLAVE ? `&${getGlobalQueryLabel(NavigateGlobalQuery.SLAVE)}=true` : ''}`;
    } else {
      window.location.href = `/login?${mode === SessionMode.SLAVE ? `${getGlobalQueryLabel(NavigateGlobalQuery.SLAVE)}=true` : ''}`;
    }
  });
  apiClient.addEventListener('tokenRefresh', (tokens) => {
    if (!tokens) return;
    const { token, refreshToken } = tokens;
    if (token) {
      setLocalStorageToken(token, TokenType.Normal);
      store.dispatch(setToken(token));
    }
    if (refreshToken) {
      setLocalStorageToken(refreshToken, TokenType.Refresh);
      store.dispatch(setRefreshToken(refreshToken));
    }
  });
  apiClient.addEventListener('tokenExpire', () => {
    const mode = store.getState().session.mode;
    const reason = 'sessionexpired';
    store.dispatch(logout());
    store.dispatch(setSocketStatus(SocketStatus.Offline));
    window.location.href = `/forbidden?r=${reason}${mode === SessionMode.SLAVE ? `&${getGlobalQueryLabel(NavigateGlobalQuery.SLAVE)}=true` : ''}`;
  });

  return (next) => async (action) => {
    const result = reduxActionMatcher(apiClient, store, action);
    if (result) return result;
    return next(action);
  };
};
