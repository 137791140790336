import KastAppClient, { CompatibilityInfo as CompatibilityInfoApiRest, LOGIN_LEVEL, Tokens } from "@kalyzee/kast-app-module";
import { addToPathNameUrl } from "@kalyzee/kast-app-web-components";
import { WebSocket, WebSocketOptions, CompatibilityInfo as CompatibilityInfoWebsocketServer } from "@kalyzee/kast-websocket-module";
import { MiddlewareAPI } from "@reduxjs/toolkit";
import { HttpStatusCode } from "../constants/httpStatusCode";
import { logout } from "../store/session/slices";
import { ApiError } from "./apiError";
import { getEnvApiUrl, getEnvOAuth2ClientId } from "./env";
import storage, { getToken, TokenType } from "./storage";

enum ApiMode {
  PROD = "production",
  DEV = "dev",
  DRO = "dro",
  ABO = "abo",
  TEST = "test",
  LOCAL = "local",
  GUYANE = "guyane",
  IP = "ip",
}
const IP = "192.168.1.58";
let API_MODE = ApiMode.PROD;

const getUrl = () => new URL(window.location.href);

// Source: react-boilerplate => https://github.com/react-boilerplate/react-boilerplate  (app/utils/request.js)

/**
 * Build backend url
 *
 * @returns {string} Returns a url as string
 */
const buildBaseUrl = (protocol: string) => {
  const url = getUrl();
  const hostNameSplitted = url.hostname.split(".");
  hostNameSplitted.splice(0, 1);
  const hostNameStr = hostNameSplitted.join(".");
  return `${protocol}//api.${hostNameStr}`;
};

/**
 * Get the current backend base (root) url
 *
 * @returns {string} Returns a url as string
 */
export const getBaseUrl = () => {
  if (API_MODE === ApiMode.DEV) return "https://api.kalyzee.dev.kast.app/";
  if (API_MODE === ApiMode.ABO) return "https://abo.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b/";
  if (API_MODE === ApiMode.DRO) return "https://dro-1.dev.kalyzee.com/606ee9c4bf11b4001c9ac54b/";
  if (API_MODE === ApiMode.LOCAL) return "http://localhost:8080/606ee9c4bf11b4001c9ac54b/";
  if (API_MODE === ApiMode.IP) return `http://${IP}:8080/606ee9c4bf11b4001c9ac54b/`;
  if (API_MODE === ApiMode.TEST) return "https://api.kalyzee.dev.kast.app/";
  if (API_MODE === ApiMode.GUYANE) return "https://api.guyane.kast.app/";
  const envApiUrl = getEnvApiUrl();
  if (envApiUrl) return envApiUrl;
  const url = getUrl();
  return buildBaseUrl(url.protocol);
};

const getSocketBaseUrl = () => {
  if (API_MODE === ApiMode.DEV) return "https://api.kalyzee.dev.kast.app/";
  if (API_MODE === ApiMode.ABO) return "https://abo.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.DRO) return "https://dro-1.dev.kalyzee.com/?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.LOCAL) return "http://localhost:8080/?organization=606ee9c4bf11b4001c9ac54b";
  if (API_MODE === ApiMode.IP) return `http://${IP}:8080/?organization=606ee9c4bf11b4001c9ac54b`;
  if (API_MODE === ApiMode.TEST) return "https://api.kalyzee.dev.kast.app/";
  if (API_MODE === ApiMode.GUYANE) return "https://api.guyane.kast.app/";
  const envApiUrl = getEnvApiUrl();
  if (envApiUrl) {
    const urlObj = new URL(envApiUrl);
    // Keep same security level : http -> ws & https -> wss
    urlObj.protocol = urlObj.protocol.replace("http", "ws");
    return urlObj.href;
  }
  const url = getUrl();
  // Keep same security level : http -> ws & https -> wss
  return `${buildBaseUrl(url.protocol.replace("http", "ws"))}`;
};

export const getUserSocketUrl = () => addToPathNameUrl(getSocketBaseUrl(), "/user");

export const getAuthHeaders = (token: string) => ({ Authorization: `Bearer ${token}` });

// TODO : token retrieval
export const getHeaders = (authenticated: boolean) => {
  let headers = {
    "Content-Type": "application/json",
  };
  if (authenticated) {
    const token = storage.getToken();
    if (token) {
      const authHeaders = getAuthHeaders(token);
      headers = { ...headers, ...authHeaders };
    }
  }
  return headers;
};

export const getInit = (method: string, body?: object, authenticated: boolean = true): RequestInit => {
  const init = {
    method,
    body: JSON.stringify(body as BodyInit),
    headers: getHeaders(authenticated),
  };
  return init;
};

export const request = async (store: MiddlewareAPI, url: string, method: string, body?: object, authenticated: boolean = true): Promise<any> => {
  const fullUrl = addToPathNameUrl(getBaseUrl(), url);
  const response = await fetch(fullUrl, getInit(method, body, authenticated));
  const data = await response.json();
  if (response.ok) {
    return data;
  }
  if (response.status === HttpStatusCode.Forbidden || response.status === HttpStatusCode.TokenIsExpired) {
    store.dispatch(logout());
  }
  const error: ApiError = new ApiError(data);
  // The api error is described by the JSON body :
  throw error;
};

export const getOAuth2ClientId = (): string | undefined => {
  if (API_MODE === ApiMode.DEV) return "64b03bc1a0733b00129a8e96";
  if (API_MODE === ApiMode.ABO) return "65258b7823e27f008b3d3127";
  if (API_MODE === ApiMode.DRO) return "64b03bc1a0733b00129a8e96";
  if (API_MODE === ApiMode.LOCAL) return "64b03bc1a0733b00129a8e96";
  if (API_MODE === ApiMode.IP) return `64b03bc1a0733b00129a8e96`;
  if (API_MODE === ApiMode.TEST) return "64b03bc1a0733b00129a8e96";
  if (API_MODE === ApiMode.GUYANE) return "64b03bc1a0733b00129a8e96";
  return getEnvOAuth2ClientId();
};

const tokens: Tokens = {
  token: getToken(TokenType.Normal) ?? undefined,
  refreshToken: getToken(TokenType.Refresh) ?? undefined,
};
export const apiClient = new KastAppClient(getBaseUrl(), LOGIN_LEVEL.NORMAL, tokens);

const options: WebSocketOptions = { url: getUserSocketUrl(), retryAmount: 5, retryDelay: 5000 };
export const websocketClient = new WebSocket(options);

export interface CompatibilityInfos {
  apiREST: CompatibilityInfoApiRest;
  websocketServer: CompatibilityInfoWebsocketServer;
}
export const getCompatibilityInfos = async (): Promise<Partial<CompatibilityInfos>> => {
  const result: Partial<CompatibilityInfos> = {};
  const apiRestCompatInfo = await apiClient.getCompatibilityInfo();
  if (apiRestCompatInfo) {
    result.apiREST = apiRestCompatInfo;
    const websocketCompatInfo = await websocketClient.getCompatibilityInfo(apiRestCompatInfo.apiVersion, "version");
    if (websocketCompatInfo) {
      result.websocketServer = websocketCompatInfo;
    }
  }
  return result;
};
