import { createAction } from '@reduxjs/toolkit';
import { RequestLoginGuest, RequestLoginLocal, RequestOauth2AllowDevice } from '../../interfaces/request';

export const requestLoginLocal = createAction<RequestLoginLocal>('request_login_local');
export const requestLoginGuest = createAction<RequestLoginGuest>('request_login_guest');
export const requestLogout = createAction('request_logout');
export const requestGetMe = createAction('request_getme');
export const requestSocketToken = createAction('request_socket_token');
export const requestOauth2AllowDevice = createAction<RequestOauth2AllowDevice>('request_oauth2_allow_device');


const requestActions = {
  requestLoginLocal,
  requestLoginGuest,
  requestLogout,
  requestGetMe,
  requestSocketToken,
  requestOauth2AllowDevice,
}

export default requestActions;
